.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
}

button {
  margin-left: 10px;
}

a:visited {
  color: black;
}

a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 3200px) {
  img {
    max-width: 300px;
    vertical-align: middle;
  }
}

@media only screen and (max-width: 1600px) {}

@media only screen and (max-width: 600px) {
  img {
    max-width: 225px;
    vertical-align: middle;
  }
}

.title  {
  color: white;
  background-color: rgba(255, 0, 0, 0.6);
  padding: 20px;
  margin: auto;
  width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner0 {
  position: fixed;
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner2 {
  position: fixed;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
